import React from 'react';
import GalleryVideo from "./GalleryVideo";

class Gallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibleEvent: 6,
            typeEvent: false,
            visibleAAValues: 6,
            typeAAValues: false,
        };

        this.loadMoreEvent = this.loadMoreEvent.bind(this);
        this.loadMoreAAValues = this.loadMoreAAValues.bind(this);
    }

    loadMoreEvent() {
        this.setState({typeEvent: 'load-video'});
        this.setState((prev) => {
           return {visibleEvent: prev.visibleEvent + 6};
        });
    }

    loadMoreAAValues() {
        this.setState({typeAAValues: 'load-video'});
        this.setState((prev) => {
           return {visibleAAValues: prev.visibleAAValues + 6};
        });
    }

    render() {

        let galleryVideos = this.props.participant.map((data, index) => {
            return (
                <GalleryVideo data={data} key={data.name} video={'Contestant'} sendTrackingEventPlay={this.props.sendTrackingEventPlay}/>
            );
        });

        let eventVideos = this.props.event_videos.slice(0, this.state.visibleEvent).map((data, index) => {
            if (!this.state.type === false) {
                return(
                    <GalleryVideo data={data} key={data.name} video={'Event'} type={this.state.typeEvent} sendTrackingEventPlay={this.props.sendTrackingEventPlay}/>
                );
            } else {
                return (
                    <GalleryVideo data={data} key={data.name} video={'Event'} type={this.state.typeEvent} sendTrackingEventPlay={this.props.sendTrackingEventPlay}/>
                );
            }
        });

        let eventVideosAAValues = [].concat(this.props.aa_values_videos)
            .sort((a, b) => parseInt(a.order) - parseInt(b.order))
            .slice(0, this.state.visibleAAValues)
            .map((data, index) => {
            if (!this.state.typeAAValues === false) {
                return(
                    <GalleryVideo data={data} key={data.name} video={'Values'} type={this.state.typeAAValues} sendTrackingEventPlay={this.props.sendTrackingEventPlay}/>
                );
            } else {
                return (
                    <GalleryVideo data={data} key={data.name} video={'Values'} type={this.state.typeAAValues} sendTrackingEventPlay={this.props.sendTrackingEventPlay}/>
                );
            }
        });

        return (
            <div className="gallery">
                <h2>Video Gallery</h2>
                <div className="gallery__wrapper gallery__wrapper--contestant">
                    <h3 className="gallery__subtitle">Meet the contenders</h3>
                    <div className="gallery__content">
                        {galleryVideos}
                    </div>
                </div>
                <div className="gallery__wrapper gallery__wrapper--competition">
                    <h3 className="gallery__subtitle">Event Videos</h3>
                    <div className="gallery__content">
                        {eventVideos}
                    </div>
                    { this.state.visibleEvent < this.props.event_videos.length &&
                    <button onClick={this.loadMoreEvent} className="load-more">Load more videos</button>
                    }
                </div>
                <div className="gallery__wrapper gallery__wrapper--competition">
                    <h3 className="gallery__subtitle">Ant's principles</h3>
                    <div className="gallery__content">
                        {eventVideosAAValues}
                    </div>
                    { this.state.visibleAAValues < this.props.aa_values_videos.length &&
                    <button onClick={this.loadMoreAAValues} className="load-more">Load more videos</button>
                    }
                </div>
            </div>
        );
    }
}

export default Gallery;
