import React, { Component } from 'react';

class Countdown extends Component {
  constructor(props) {
    super(props);

    const {days, hours, minutes} = this.props.dateObject.timeDiff;
    const endDate = new Date();

    // console.log(days, hours, minutes);

    endDate.setDate(endDate.getDate() + days);
    endDate.setHours(endDate.getHours() + hours);
    endDate.setMinutes(endDate.getMinutes() + minutes);

    this.state = {
      days: this.props.dateObject.timeDiff.days,
      hours: this.props.dateObject.timeDiff.hours,
      min: this.props.dateObject.timeDiff.minutes,
      endDate
    };

    this.calculateCountdown = this.calculateCountdown.bind(this);
  }

  componentDidMount() {
    // update every minute
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.state.endDate);
      date ? this.setState(date) : this.stop();
    }, 60000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff < 0) return false;

    const timeLeft = {
      days: this.state.days,
      hours: this.state.hours,
      min: this.state.min
    };

    let hours = false;

    console.log(diff);

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff < 86400 && timeLeft.hours === 23) {
        timeLeft.days = 0;
    }

    if (diff > 3600) { // 60 * 60
      hours = true;
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }

    // if (diff <= 3600 && timeLeft.days === 0) {
    //     timeLeft.hours = 0;
    // }

    if (diff >= 1) {
      if (diff < 3600 && !hours) {
        console.log('diff less than 3600 so reducing hours to 0');
        timeLeft.hours = 0;
      }
      timeLeft.min = Math.floor(diff / 60);
      if (timeLeft.min === 60 && timeLeft.hours === 1) {
        console.log('Mins left = 60 and hours = 1 so we are reducing min to 0 and setting hours to 1');
        timeLeft.hours = 1;
        timeLeft.min = 0;
      }
      diff -= (timeLeft.min * 60);
    } else {
      timeLeft.min = 0;
    }

    timeLeft.sec = diff;

    console.log(timeLeft);
    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {

    const countDown = this.state;

    return (
      <div className="hero__countdown">
        <div className="hero__countdown__time">
          <span className="hero__countdown__label">{countDown.days === 1 ? 'Day' : 'Days'}</span>
          <span>{this.addLeadingZeros(countDown.days)}</span>
        </div>

        <div className="hero__countdown__time">
          <span className="hero__countdown__label">Hours</span>
          <span>{this.addLeadingZeros(countDown.hours)}</span>
        </div>

        <div className="hero__countdown__time">
          <span className="hero__countdown__label">Minutes</span>
          <span>{this.addLeadingZeros(countDown.min)}</span>
        </div>
      </div>
    );
  }
}

Countdown.defaultProps = {
  date: new Date()
};

export default Countdown;
