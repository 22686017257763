import React from 'react';
import Logo from '../images/aa-careers-logo.svg';
import Leaderboard from '../images/leaderboard.svg';
import LeaderboardHover from '../images/leaderboard-hover.svg';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            img: Leaderboard,
        }
    }

    displayLeaderboard() {

        const toggleModal = () => {
            const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

            const targetID = '.leaderboard';
            const targetAnchor = document.querySelector(targetID);
            if (!targetAnchor) return;
            const originalTop = distanceToTop(targetAnchor);

            window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

            const checkIfDone = setInterval(function() {
                const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
                if (distanceToTop(targetAnchor) === 0 || atBottom) {
                    targetAnchor.tabIndex = "-1";
                    targetAnchor.focus();
                    // window.history.pushState("", "", targetID);
                    clearInterval(checkIfDone);
                }
            }, 100);

        };

        if (!this.props.content.challengeState === false) {
            return (
                <button
                    className="header__logo--leaderboard"
                    onClick={
                        () => {
                            this.props.sendTrackingEvent({
                                event_category: 'Leaderboard',
                                event_label: 'Leaderboard'
                            });

                            toggleModal();
                        }

                    }
                >
                    <img
                        src={this.state.img}
                        alt=""
                        onMouseEnter={() => {
                            this.setState({
                                img: LeaderboardHover,
                            })
                        }}
                        onMouseOut={() => {
                            this.setState({
                                img: Leaderboard,
                            })
                        }}
                        // style={{transition: this.state.hovered ? '0.6s' :  '0.6s'}}
                    />
                </button>
            );
        }
    }


    render() {

        // const brandLogoVisible = this.props.content.challengeState !== false ? 'visible' : 'hidden';

        return (
            <div className="header">
                <div className="header__left">
                    <img src="./images/brand-logo.png" className="header__brand" alt="Ant Middleton's Ready for anything?"/>
                    <span className="header__hashtag" style={{display: !this.props.content.challengeState === false ? 'block' : 'none'}}>{this.props.social.hashtag}</span>
                </div>
                <div className="header__right">
                    <a
                        href={this.props.global.logoURL}
                        className="header__logo"
                        title="AA Careers"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={
                            () => {
                                this.props.sendTrackingEvent(
                                    {
                                        event_category: 'Careers Site',
                                        event_label: 'Header'
                                    }
                                )
                            }
                        }
                    >
                        <img src={Logo} alt="AA Careers"/>
                    </a>
                    {this.displayLeaderboard()}
                </div>
            </div>
        );
    }
}

export default Header;
