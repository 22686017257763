import React, {useState} from 'react';
import ModalVideo from "./ModalVideo";

const ParticipantVideo = ({data, sendTrackingEventPlay}) => {

    const {video_url, profile_image} = data;

    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    return (
        <ModalVideo videoURL={video_url} withState={false} isShow={show} toggleModal={toggleShow} event_category="Video" event_label={'Leaderboard | ' + data.name} sendTrackingEventPlay={sendTrackingEventPlay}>
            <img src="" data-src={profile_image} alt="" className="participant__image lazy-loading" onClick={toggleShow} style={{ height:'247px', width: '100%'}}/>
        </ModalVideo>
    );

};

export default ParticipantVideo;
