import React, {useState} from 'react';
import ModalVideo from "./ModalVideo";

const GalleryVideo = ({data, type, video, sendTrackingEventPlay}) => {

    const {video_url, video_poster, name} = data;

    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    const displayImage = () => {
        if (type !== undefined && type !== false) {
            return(
                <img src={video_poster} alt={name} onClick={toggleShow} />
            );
        } else {
            return(
                <img src="" data-src={video_poster} alt={name} onClick={toggleShow} className="lazy-loading" style={{height:'247px', width: '100%'}}/>
            );
        }
    };

    return (
        <ModalVideo videoURL={video_url} withState={false} isShow={show} toggleModal={toggleShow} event_category="Video" event_label={video + ' | ' + name} sendTrackingEventPlay={sendTrackingEventPlay}>
            <div className="gallery__video">
                <div className="gallery__video__image">
                    {displayImage()}
                    <button className="gallery__video__button" onClick={toggleShow}>
                        <span className="visually-hidden">Button</span>
                    </button>
                </div>
                <div className="gallery__video__title">
                    <button className="gallery__video__button" onClick={toggleShow}>
                        <span className="visually-hidden">Button</span>
                    </button>
                    <span onClick={toggleShow}>{name}</span>
                </div>
            </div>
        </ModalVideo>
    );

};

export default GalleryVideo;
