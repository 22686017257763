import React, { Component } from 'react';
import Countdown from './Countdown';
import Social from './Social';
import ReactPlayer from "react-player";
import Spinner from '../images/spinner.gif';

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import ModalVideo from "./ModalVideo";

class Hero extends Component {

    constructor(props){
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            play: false,
            open: null,
            brandVisible: false,
        };

        this.mobileMedia = window.matchMedia('(max-width: 768px)');
    }

    componentDidUpdate() {

        if (this.mobileMedia.matches === false) {
            Splitting({
                target: '.hero__overlay',
                by: 'cells',
                rows: 2
            });
        }
    }

    toggleClass() {
        const currentState = this.state.play;
        this.setState({
            play: !currentState,
            open: !currentState
        });
    };

    videoButton() {
        if (this.props.content.videoLink && this.props.content.buttonText) {

            if (this.mobileMedia.matches) {

                const loaderClass = this.state.play ? 'button hero__button hero__button--disable' : 'button hero__button';

                return(
                    <button onClick={this.toggleClass} className={loaderClass}>
                        {this.props.content.buttonText}
                        <img src={Spinner} alt="loader" className="hero__button__loader"/>
                    </button>
                );
            } else {
                return (
                    <button onClick={this.toggleClass} className="button hero__button">
                        {this.props.content.buttonText}
                    </button>
                );
            }
        }
    }

    displayCountdown() {
        if (this.props.content.hasCountdown === false && this.state.brandVisible === false) {
            this.setState({brandVisible: true});
            return false;
        } else if (this.props.content.countdown && this.state.brandVisible === false) {
            const setTime = new Date(this.props.content.countdown.date.replace(/\s/, 'T'));
            setTime.setHours(setTime.getHours() - 1); //to match the offset of an hour time difference with the server time
            const currentTime = new Date(this.props.content.countdown.dateNow.replace(/\s/, 'T'));
            if (setTime < currentTime) {
                this.setState({brandVisible: true});
                return false;
            } else {
                const {days, hours, minutes} = this.props.content.countdown.timeDiff;

                const endDate = new Date();

                endDate.setDate(endDate.getDate() + days);
                endDate.setHours(endDate.getHours() + hours);
                endDate.setMinutes(endDate.getMinutes() + minutes);

                //check if the time is remaining in countdown
                const timeInterval = setInterval(() => {
                    let calcTime = (Date.parse(endDate) - Date.parse(new Date())) / 1000;

                    if ( calcTime <= 59) {
                        this.setState({brandVisible: true});
                        clearInterval(timeInterval);
                    }

                }, 60000); //60000 every minute

                if (this.state.brandVisible) {
                    clearInterval(timeInterval);
                }
            }
        }

        if (this.state.brandVisible === false && !this.props.content.hasCountdown === false) {
            return (
                <Countdown time={this.props.time} dateObject={this.props.content.countdown} />
            );
        }
    }

    displayHorizontalSocial() {
        if (this.mobileMedia.matches === false) {
            return (
                <Social content={this.props.social} voting={this.props.content} social_live={true} type="voting-links" sendTrackingEvent={this.props.sendTrackingEvent}/>
            );
        }
    }

    content() {

        const onPause = () => {
            if (this.mobileMedia.matches) {
                this.toggleClass();
            }
        };

        const smallMobile = window.matchMedia('(max-width: 480px)');
        smallMobile.addListener(toggleBrandLogo);
        let brandLogoVisible = false;

        function toggleBrandLogo(smallMobile) {
            if (smallMobile.matches) {
                brandLogoVisible = true;
            }
        }

        const backgroundImage = 'url( ' + this.props.content.backgroundImage + ')';
        const social = this.props.social;
        const description = this.mobileMedia.matches && this.state.brandVisible ? 'block' : 'none';
        const brandLogo = this.state.brandVisible || brandLogoVisible || !this.props.content.hasCountdown ? 'block' : false;

        const Player = () => {
            const ua = navigator.userAgent.toLowerCase();
            const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
            if(isAndroid) {
                return (
                    <ModalVideo videoURL={this.props.content.videoLink} withState={false} isShow={this.state.play} playsInline={true} toggleModal={this.toggleClass} event_category="Video" event_label="Hero Video" sendTrackingEventPlay={this.props.sendTrackingEventPlay}>
                    </ModalVideo>
                );
            } else {
                const onStart = () => {
                    this.props.sendTrackingEventPlay({
                        event_category: 'Video',
                        event_label: 'Hero Video',
                    });
                };

                return (
                    <div className="hero__overlay"
                         style={{backgroundImage: backgroundImage}} >
                        <div className="hero__overlay__image">
                            <ReactPlayer
                                className='react-player'
                                url={this.props.content.videoLink}
                                width='100%'
                                height='100%'
                                onStart={onStart}
                                playing={this.state.play}
                                controls={true}
                                playsinline={false}
                                preload={true}
                                onPause={onPause}
                                allowFullScreen
                            />
                            <button className="close" onClick={this.toggleClass}>
                                <div className="close__wrapper">
                                    <span className="close__line close__line--1"></span>
                                    <span className="close__line close__line--2"></span>
                                    <span className="visually-hidden">Close</span>
                                </div>
                            </button>
                        </div>
                    </div>
                );
            }
        };

        switch(this.props.content.challengeState) {

            case 'challengeLive':
                return (
                    <div className='hero hero--has-livestream'>
                        {this.displayHorizontalSocial()}
                        <iframe
                            src="https://kinuracdn.s3-eu-west-2.amazonaws.com/aa/aa-cb.html"
                            height="100%"
                            width="100%"
                            frameBorder="0"
                            scrolling="no"
                            className="live-feed"
                            title="video"
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                );
            default :
                return (
                    <div className={this.state.open && this.mobileMedia.matches === false ? 'hero open' : 'hero'} style={{ backgroundImage: backgroundImage}}>
                        <div className="hero__content hero__content--top">
                            <div className="hero__content__wrapper">
                                <img src="../images/brand-logo.png" className="hero__brand" alt="" style={{display: brandLogo}}/>
                                <p className="hero__content__description" style={{display: description}}>{this.props.content.description}</p>
                                {this.displayCountdown()}
                            </div>
                        </div>
                        <div className="hero__content hero__content--bottom">
                            <div className="hero__content__wrapper">
                                <p className="hero__content__description">{this.props.content.description}</p>
                                <Social content={social} voting={this.props.content} sendTrackingEvent={this.props.sendTrackingEvent}/>
                            </div>
                            {this.videoButton()}
                        </div>
                        {Player()}
                    </div>
                );
        }
    }

    render() {

        return (
            this.content()
        );
    }
}

export default Hero;
