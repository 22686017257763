import React from 'react';
import Social from "./Social";

const Challenge = props => {

    const displaySocialVoting = () => {

        if (props.content.votingState === null || props.content.votingState === false ) {
            return (
                <React.Fragment>
                    <p>{props.social.votingOpen}</p>
                    <Social content={props.social} voting={props.content} type="voting-links" sendTrackingEvent={props.sendTrackingEvent}/>
                </React.Fragment>
            );
        } else {
            return(
                <p>{props.social.votingClosed}</p>
            );
        }
    };

    return (
        <div className="challenge">
            <h2 className="challenge__heading">The Challenges</h2>
            <div className="challenge__wrapper">
                {/*<div className="challenge__map" style={{backgroundImage: `url(${props.content.map})`}}>*/}
                {/*</div>*/}
                <img src="" data-src={props.content.map} alt="" className="challenge__map lazy-loading" style={{height:'750px', width: '100%'}}/>
                <div className="challenge__content">
                    <h3 className="challenge__content__subheading">Challenge</h3>
                    <span className="challenge__content__number">{props.content.challengeNumber}</span>
                    <div className="challenge__content__description" dangerouslySetInnerHTML={ {__html: props.content.challengeDescription} } />
                    <img src="" data-src={props.content.votingOptions} alt="" className="challenge__content__voting lazy-loading" style={{height:'340px', width: '100%'}} />
                    {displaySocialVoting()}
                </div>
            </div>
        </div>
    );
};

export default Challenge;
