import React from 'react';

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="footer__inner">
                <h2>Are You</h2>
                <h2 className="footer__banner-text">Ready For Anything?</h2>
                <p>Search for jobs at The AA Careers</p>
                <a
                    href="https://www.theaacareers.co.uk/"
                    title="Search for jobs at The AA Careers"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__button"
                    onClick = {
                        () => {
                            props.sendTrackingEvent({
                                event_category: 'Careers Site',
                                event_label: 'Footer'
                            })
                        }
                    }
                >
                    Find out more
                </a>
                <ul className="footer__links">
                    <li><a href="http://www.theaa.com/privacy-notice" title="Privacy policy" >Privacy Policy</a></li>
                    <li><a href="https://www.theaa.com/cookie-policy" title="Privacy policy">Cookies</a></li>
                    <li><a href="https://www.theaacareers.co.uk/" title="Careers">Careers</a></li>
                    <li><a href="https://www.theaa.com/" title="Corporate">Corporate</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
