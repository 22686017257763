import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Challenge from './components/Challenge';
import Participant from "./components/Participant";
import Gallery from "./components/Gallery";
import Axios from 'axios';
import './styles.scss';
import Footer from "./components/Footer";
// import './library/gtag-tmpw';

// import 'normalize.css';


class App extends React.Component {

    constructor() {
        super();

        this.state = {
            order: [],
            initialStep: false,
            content: [],
            about: [],
            social: [],
            participants: [],
            event_videos: [],
            aa_values_videos: [],
            global: [],
        };

        this.gtag = false;
        this.gtagConfig = 'UA-5970778-3';
        this.sendTrackingEvent = this.sendTrackingEvent.bind(this);
        this.sendTrackingEventPlay = this.sendTrackingEventPlay.bind(this);

        this.updaterInterval = false;
    }

    componentDidMount() {
        Axios.get(`https://ant24livestg.wpenginepowered.com/wp-json/wp/v2/content`)
            .then(response => {
                this.setState({
                    stepID: response.data.currentStep.stepID,
                    order: response.data.currentStep.order,
                    initialStep: response.data.currentStep.initialStep,
                    challengeState: response.data.currentStep.challengeState,
                    hasCountdown: response.data.currentStep.hasCountdown,
                    countdown: response.data.currentStep.countdown,
                    votingState: response.data.currentStep.votingState,
                    content: response.data.currentStep,
                    about: response.data.globals.about,
                    social: response.data.globals.social,
                    participants: response.data.participants,
                    event_videos: response.data.event_videos,
                    aa_values_videos: response.data.aa_values_videos,
                    global: response.data.globals,
                })
            });

        this.updaterInterval = setInterval(() => {

            Axios.get(`https://ant24livestg.wpenginepowered.com/wp-json/wp/v2/updated`)
                .then(response => {
                    const possibleChangedData = response.data.currentStep;

                    Object.keys(possibleChangedData).forEach(key => {
                        const currentData = this.state[key];
                        const changedData = possibleChangedData[key];

                        if (currentData !== changedData) {

                            if (this.state.participants !== possibleChangedData.participants) {
                                this.setState({
                                    participants: possibleChangedData.participants,
                                });
                            } else {
                                const notChanged = changedData.hasOwnProperty('date') && currentData.hasOwnProperty('date') && currentData.date === changedData.date;

                                if (!notChanged) {
                                    window.location.reload();
                                }
                            }
                        }
                    });

                })
                .catch(error => {
                    console.log(error);
                    window.location.reload();
                });

        }, 15000);

        window.addEventListener('resize', () => {
            if (window.innerWidth < 768) {
                this.backToTop();
            }
        }, false);
    }

    componentDidUpdate() {
        /**
         * Google Analytics data
         * @type {*|Array}
         */

        const dataLayer = window.dataLayer = window.dataLayer || [];
        this.gtag = function gtag(){dataLayer.push(arguments);}
        this.gtag('js', new Date());

        this.gtag('config', this.gtagConfig);
        /**
         * Google Analytics end
         */

        let lazyImages = [].slice.call(document.querySelectorAll(".lazy-loading"));
        let active = false;

        const lazyLoad = function () {
            if (active === false) {
                active = true;

                setTimeout(function () {
                    lazyImages.forEach(function (lazyImage) {
                        if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                            lazyImage.src = lazyImage.dataset.src;
                            // lazyImage.srcset = lazyImage.dataset.srcset;
                            lazyImage.classList.remove("lazy-loading");
                            lazyImage.style.height = 'auto';
                            lazyImage.style.width = null;

                            lazyImages = lazyImages.filter(function (image) {
                                return image !== lazyImage;
                            });

                            if (lazyImages.length === 0) {
                                document.removeEventListener("scroll", lazyLoad);
                                window.removeEventListener("resize", lazyLoad);
                                window.removeEventListener("orientationchange", lazyLoad);
                            }
                        }
                    });

                    active = false;
                }, 200);
            }
        };

        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
    }

    backToTop() {

        window.onscroll = function changeClass() {
            const scrollPosY = window.pageYOffset | document.body.scrollTop;

            // const el = document.createElement('div');
            // el.setAttribute('id', 'backToTop');

            const backtoTop = document.querySelector('#backToTop');

            // body.appendChild(el);

            if (scrollPosY > 150) {
                backtoTop.classList.add('active');
            } else {
                backtoTop.classList.remove('active');
            }

            backtoTop.addEventListener('click', function (e) {
                const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

                e.preventDefault();
                const targetID = 'body';
                const targetAnchor = document.querySelector(targetID);
                if (!targetAnchor) return;
                const originalTop = distanceToTop(targetAnchor);

                window.scrollBy({top: originalTop, left: 0, behavior: "smooth"});

                const checkIfDone = setInterval(function () {
                    const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
                    if (distanceToTop(targetAnchor) === 0 || atBottom) {
                        targetAnchor.tabIndex = "-1";
                        targetAnchor.focus();
                        window.history.pushState("", "", targetID);
                        clearInterval(checkIfDone);
                    }
                }, 100);
            });
        }
    }

    sendTrackingEvent(event) {
        this.gtag('event', 'click', event);
    }

    sendTrackingEventPlay(event) {
        this.gtag('event', 'play', event);
    }

    getInnerContent() {
        let innerContent = [];
        const order = this.state.order;

        if ((this.state.initialStep === 'initial' && order.length >= 2) || (this.state.initialStep !== 'initial' && order.length === 4) ) {
            order.forEach(componentString => {
                let componentToAdd = false;

                switch (componentString) {
                    default:
                        return false;

                    case 'about' :
                        componentToAdd = <About className="section" content={this.state.about} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent} sendTrackingEventPlay={this.sendTrackingEventPlay}/>;
                        break;

                    case 'participant' :
                        if (this.state.initialStep !== 'initial') {
                            componentToAdd =
                                <Participant content={this.state.participants} description={this.state.global} sendTrackingEventPlay={this.sendTrackingEventPlay}/>;
                        }
                        break;

                    case 'challenge' :
                        if (this.state.initialStep !== 'initial') {
                            componentToAdd =
                                <Challenge content={this.state.content} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent}/>;
                        }
                        break;

                    case 'gallery' :
                        componentToAdd = <Gallery participant={this.state.participants} event_videos={this.state.event_videos}
                                                  aa_values_videos={this.state.aa_values_videos} sendTrackingEventPlay={this.sendTrackingEventPlay}/>;
                        break;
                }

                innerContent = [...innerContent, componentToAdd]
            });
        } else {
            innerContent = this.state.initialStep === 'initial' ?
            [
                <About content={this.state.about} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent} sendTrackingEventPlay={this.sendTrackingEventPlay}/>,
                <Gallery participant={this.state.participants} event_videos={this.state.event_videos} aa_values_videos={this.state.aa_values_videos} sendTrackingEventPlay={this.sendTrackingEventPlay}/>
            ]
                :
            [
                <About content={this.state.about} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent} sendTrackingEventPlay={this.sendTrackingEventPlay}/>,
                <Challenge content={this.state.content} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent}/>,
                <Participant content={this.state.participants} description={this.state.global} sendTrackingEventPlay={this.sendTrackingEventPlay}/>,
                <Gallery participant={this.state.participants} event_videos={this.state.event_videos} aa_values_videos={this.state.aa_values_videos} sendTrackingEventPlay={this.sendTrackingEventPlay}/>
            ];
        }

        return innerContent;
    }

    render() {

        let content = false;
        const innerContent = this.getInnerContent();

        if (this.state.initialStep === 'initial') {
            content =
                <div className="main">
                    <Header content={this.state.content} social={this.state.social} global={this.state.global} sendTrackingEvent={this.sendTrackingEvent}/>
                    <Hero content={this.state.content} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent} sendTrackingEventPlay={this.sendTrackingEventPlay}/>
                    {innerContent}
                    <Footer sendTrackingEvent={this.sendTrackingEvent}/>
                </div>
        } else {
            content =
                <div className="main steps">
                    <Header content={this.state.content} social={this.state.social} global={this.state.global} sendTrackingEvent={this.sendTrackingEvent}/>
                    <Hero content={this.state.content} social={this.state.social} sendTrackingEvent={this.sendTrackingEvent} sendTrackingEventPlay={this.sendTrackingEventPlay}/>
                    {innerContent}
                    <Footer sendTrackingEvent={this.sendTrackingEvent}/>
                </div>
        }

        return content;
    }
}

ReactDOM.render(<App/>, document.getElementById('root'));






