import React from 'react';

const Social = props => {

    let challenge,
        challengeEventCategory,
        challengeLiveCategory;

    if (props.voting) {
        // const challengeState = props.voting.challengeState !== false ? 'Challenge Live Social' : 'Challenge Vote';
        const challengeName = props.voting.votingState === false ? 'challenge ' + props.voting.challengeNumber : 'Hero';
        challenge = props.voting.initialStep ? 'Hero' : challengeName ;
        challengeEventCategory = props.voting.initialStep === false && props.voting.votingState === false ? 'Challenge Vote Hero' : 'Social';
        challengeLiveCategory = props.social_live ? 'Challenge Live Social' : 'Challenge Vote';
    }

    const socialMenu = () => {
        switch(props.type) {
            case 'voting-links':
                return (
                    <div className="social social--voting">
                        <ul className="social__icons social__icons--small">
                            <li>
                                <a
                                    href={props.voting.twitterVotingLInk}
                                    title="Twitter"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="twitter"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: challengeLiveCategory,
                                                event_label: 'Twitter | ' + challenge
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Twitter</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={props.voting.instagramVotingLInk}
                                    title="Instagram"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="instagram"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: challengeLiveCategory,
                                                event_label: 'Instagram | ' + challenge
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={props.voting.facebookVotingLInk}
                                    title="Facebook"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="facebook"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: challengeLiveCategory,
                                                event_label: 'Facebook | ' + challenge
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Facebook</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                );
            case 'social-icons':
                return (
                    <div className="social">
                        <ul className="social__icons social__icons--small">
                            <li>
                                <a
                                    href={props.content.twitter}
                                    title="Twitter"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="twitter"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: 'Social',
                                                event_label: 'Twitter | About the Event',
                                                value: 1
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Twitter</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={props.content.instagram}
                                    title="Instagram"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="instagram"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: 'Social',
                                                event_label: 'Instagram | About the Event',
                                                value: 1
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={props.content.facebook}
                                    title="Facebook"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="facebook"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: 'Social',
                                                event_label: 'Facebook | About the Event',
                                                value: 1
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Facebook</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                );
            default:
                return (
                    <div className="social">
                        <p className="social__description">
                            {props.content.socialCTA}
                        </p>
                        <ul className="social__icons">
                            <li>
                                <a
                                    href={props.voting && props.voting.initialStep === false && props.voting.votingState === false ? props.voting.twitterVotingLInk : props.content.twitter}
                                    title="Twitter"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="twitter"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: challengeEventCategory,
                                                event_label: 'Twitter | ' + challenge,
                                                value: 1
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Twitter</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={props.voting && props.voting.initialStep === false && props.voting.votingState === false ? props.voting.instagramVotingLInk : props.content.instagram}
                                    title="Instagram"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="instagram"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: challengeEventCategory,
                                                event_label: 'Instagram | ' + challenge,
                                                value: 1
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={props.voting && props.voting.initialStep === false && props.voting.votingState === false ? props.voting.facebookVotingLInk : props.content.facebook}
                                    title="Facebook"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="facebook"
                                    onClick={
                                        () => {
                                            props.sendTrackingEvent({
                                                event_category: challengeEventCategory,
                                                event_label: 'Facebook | ' + challenge,
                                                value: 1
                                            })
                                        }
                                    }
                                >
                                    <span className="visually-hidden">Facebook</span>
                                </a>
                            </li>
                        </ul>
                        <span className="social__hashtag">{props.content.hashtag}</span>
                    </div>
                );
        }
    }


    return (
        socialMenu()
    );
};

export default Social;
