import React from 'react';
import ParticipantVideo from "./ParticipantVideo";

const Participant = props => {

    let participant = [].concat(props.content)
        .sort((a, b) => parseInt(b.points_total) - parseInt(a.points_total))
        .map((data, index, array) =>
        <div className=
                 {index < (array.length / 2) ? 'participant participant__left' : 'participant participant__right'}
             key={data.name}
        >
            <span className="participant__rank">{index + 1}</span>
            <ParticipantVideo data={data} sendTrackingEventPlay={props.sendTrackingEventPlay}/>
            <div className="participant__details">
                <h3 className="participant__name">{data.name}</h3>
                <span className="participant__points">{data.points_total} pts</span>
            </div>
        </div>
    );

    return (
        <div className="leaderboard">
            <h2>Leaderboard</h2>
            <p className="leaderboard__description">{props.description.leaderboardDescription}</p>
            <div className="leaderboard__content">
                {participant}
            </div>
        </div>
    );
};

export default Participant;
