import React, {useState} from 'react';
import Social from './Social';
import ModalVideo from "./ModalVideo";

const About = props => {

    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    return (
        <div className="about">
            <h2 className="about__heading">{props.content.heading}</h2>
            <div className="about__content">
                <div className="about__content__video" style={{backgroundImage: `url(${props.content.image})`}}>
                    <ModalVideo videoURL={props.content.videoURL} withState={false} isShow={show} toggleModal={toggleShow} event_category="Video" event_label="About Video" sendTrackingEventPlay={props.sendTrackingEventPlay}>
                        <button
                            className="button button--small about__content__video__button"
                            onClick={toggleShow}
                        >
                            {props.content.buttonText}
                        </button>
                    </ModalVideo>
                </div>
                <div className="about__content__description">
                    <h3 className="about__subheading">{props.content.subHeading}</h3>
                    <div dangerouslySetInnerHTML={ {__html: props.content.description} } />
                </div>
            </div>
            <Social content={props.social} type="social-icons" sendTrackingEvent={props.sendTrackingEvent}/>
        </div>
    );
};

export default About;
