import React, {Component} from 'react';
import ReactPlayer from "react-player";

class ModalVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: props.isShow,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.ref = this.ref.bind(this);
        // this.mobileMedia = window.matchMedia('(max-width: 768px)');
    }

    static getDerivedStateFromProps(props, state) {
        return { show: props.isShow };
    }

    toggleModal(mobileOnly=false) {
        if (mobileOnly) {
            if (window.matchMedia('(max-width: 768px)').matches) {
                if (this.props.withState) {
                    this.setState({
                        show: !this.state.show
                    });
                } else {
                    this.props.toggleModal();
                }
            }
        } else {
            if (this.props.withState) {
                this.setState({
                    show: !this.state.show
                });

            } else {
                this.props.toggleModal();
            }
        }
    }

    ref(vimeoPlayer) {
        this.vimeoPlayer = vimeoPlayer;
    }

    render() {
        let playsInline = false;
        if (this.props.playsInline) {
            playsInline = true;
        }
        const className = this.state.show ? 'modal-video modal-video--visible' : ' modal-video modal-video--hidden';

        const onPause = () => {
            const ua = navigator.userAgent.toLowerCase();
            const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
            if(isAndroid === false) {
                this.toggleModal(true);
            }
        };

        const onStart = () => {
            this.props.sendTrackingEventPlay({
                event_category: this.props.event_category,
                event_label : this.props.event_label
            });
        };

        const Player = (
            <ReactPlayer
                className='modal-video__player'
                ref={this.ref}
                url={this.props.videoURL}
                width='100%'
                height='100%'
                onStart={onStart}
                onPause={onPause}
                playing={this.state.show}
                controls={true}
                playsinline={playsInline}
                preload={false}
            />
        );

        return (
            <div className="modal">
                <div className="modal__wrapper modal__click" onClick={() => {
                    if (this.props.withState) this.toggleModal();
                }}>
                    {this.props.children}
                </div>
                <div className={className}>
                    <section className='modal-video__container'>
                        <div className="modal-video__wrapper">
                            {Player}
                        </div>
                        <button className="close" onClick={() => {this.toggleModal()}}>
                            <div className="close__wrapper">
                                <span className="close__line close__line--1"></span>
                                <span className="close__line close__line--2"></span>
                                <span className="visually-hidden">Close</span>
                            </div>
                        </button>
                    </section>
                </div>
            </div>
        );
    }
}

// const ModalVideo = ({videoURL, children, withState=true, isShow=false}) => {
//
//     const [show, setShow] = useState(isShow);
//
//     const className = show ? 'modal-video modal-video--visible' : ' modal-video modal-video--hidden';
//
//     const toggleModal = () => {
//         setShow(!show);
//         document.body.classList.toggle('bg-fixed');
//     };
//
//     const showVar = withState ? show : isShow;
//
//     return (
//         <div className="modal">
//             <div className="modal__wrapper modal__click" onClick={() => {
//                 if (withState) toggleModal();
//             }}>
//                 {children}
//             </div>
//             <div className={className}>
//                 <section className='modal-video__container'>
//                     <div className="modal-video__wrapper">
//                         <ReactPlayer
//                             className='modal-video__player'
//                             url={videoURL}
//                             width='100%'
//                             height='100%'
//                             playing={showVar}
//                             controls={true}
//                         />
//                     </div>
//                     <button className="close" onClick={() => {
//                         toggleModal();
//                     }}>
//                         <span className="visually-hidden">Close</span>
//                     </button>
//                 </section>
//             </div>
//         </div>
//     );
//
// };

export default ModalVideo;
